var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-drawer',{attrs:{"visible":this.$store.state.rankEditShow,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[(_vm.id == '')?_c('a-page-header',{attrs:{"title":"新增排行榜商品","sub-title":" "}}):_c('a-page-header',{attrs:{"title":"编辑排行榜商品","sub-title":" "}}),_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical"}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"选择商品"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['goods_id', { rules: [{ required: true, message: '请输入并选择商品！' }] }]),expression:"['goods_id', { rules: [{ required: true, message: '请输入并选择商品！' }] }]"}],attrs:{"show-search":"","placeholder":"请输入并选择商品","default-active-first-option":false,"show-arrow":false,"filter-option":false,"allowClear":true,"not-found-content":null,"dropdownClassName":"dropdown","autocomplete":"off"},on:{"search":_vm.handleSearch}},_vm._l((_vm.goodsList),function(d){return _c('a-select-option',{key:d.id},[_vm._v(" "+_vm._s(d.title)+" ")])}),1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"是否展示 "}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'is_show',
              {
                rules: [{ required: true, message: '请选择是否展示!' }],
              } ]),expression:"[\n              'is_show',\n              {\n                rules: [{ required: true, message: '请选择是否展示!' }],\n              },\n            ]"}]},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 展示 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 隐藏 ")])],1)],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              {
                rules: [{ required: false, message: '请输入备注!' }],
              } ]),expression:"[\n              'remark',\n              {\n                rules: [{ required: false, message: '请输入备注!' }],\n              },\n            ]"}],attrs:{"rows":5,"placeholder":"请输入备注"}})],1)],1)],1)],1),_c('div',{style:({
      height: '80px',
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      padding: '22px 40px 0 0',
      background: '#f8f8f8',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({
        marginRight: '10px',
        padding: '0 50px',
        height: '36px',
        borderCcolor: '#dbdbdb',
      }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{style:({ padding: '0 50px', height: '36px' }),attrs:{"type":"primary"},on:{"click":_vm.onSure}},[_vm._v(" 确定 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }