<template>
  <div class="container" ref="box">
    <div ref="header" class="header pageHeader">
      <a-page-header title="排行榜管理"> </a-page-header>
    </div>
    <div ref="form" class="form">
      <a-form layout="inline" ref="form"> </a-form>
      <a-button class="buttonImg" @click="handleCell('新增', '')">
        <img src="../assets/add.png" alt="" />
        新增
      </a-button>
    </div>
    <a-table
      id="mytb"
      :columns="columns"
      size="middle"
      :scroll="{ x: 700, y: table_h }"
      :pagination="false"
      :data-source="list"
      :row-key="(record) => record.id"
    >
      <span slot="is_show" slot-scope="is_show">
        <a-switch :checked="is_show == 1" disabled />
      </span>
      <div slot="goodsInfo" slot-scope="goodsInfo" class="goodsInfo">
        <img :src="goodsInfo.mainPic" alt="" />
        <div>
          <div>
            <a-tooltip placement="top" :title="goodsInfo.title"
              ><p>{{ goodsInfo.title }}</p></a-tooltip
            >
            <div>
              <span>￥{{ goodsInfo.originalPrice }}</span>
            </div>
          </div>
          <p>
            <span style="color: rgba(253, 105, 150)">{{
              goodsInfo.shopType == 1 ? "天猫" : "淘宝"
            }}</span
            >{{ goodsInfo.brandName ? " | " + goodsInfo.brandName : "" }}
          </p>
        </div>
      </div>
      <span slot="btns" slot-scope="btns, num">
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('编辑', num)"
        >
          编辑
        </a-button>
        <a-button
          class="editable-add-btn"
          size="small"
          style="margin: 5px"
          @click="handleCell('删除', num)"
        >
          删除
        </a-button>
      </span>
    </a-table>
    <!-- <div ref="pagination" style="overflow: hidden">
      <a-pagination
        v-if="pageshow"
        :default-current="currentPage"
        :defaultPageSize="defaultPageSize"
        :page-size-options="pageSizeOptions"
        show-size-changer
        :total="total_num"
        @showSizeChange="onShowSizeChange"
        @change="onPageChange"
        :item-render="itemRender"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div> -->
    <Edit v-if="$store.state.rankEditShow" />
  </div>
</template>
<script>
import Edit from "@/components/rank/edit.vue";
import Sortable from "sortablejs"; //排序
export default {
  name: "User",
  components: {
    Edit,
  },
  data() {
    return {
      columns: [
        // {
        //   title: "商品ID",
        //   key: "dataoke_goods_id",
        //   dataIndex: "dataoke_goods_id",
        //   align: "center",
        // },
        {
          title: "商品信息",
          key: "goods",
          dataIndex: "goods",
          scopedSlots: { customRender: "goodsInfo" },
          align: "center",
          width: "270px",
        },
        {
          title: "展示",
          key: "is_show",
          dataIndex: "is_show",
          scopedSlots: { customRender: "is_show" },
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          align: "center",
        },
        {
          title: "操作",
          key: "btns",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      table_h: "",
      list: [],
      // pageshow: true,
      // currentPage: 1,
      // total_num: 0,
      // defaultPageSize: this.$store.state.defaultPageSize,
      // pageSizeOptions: this.$store.state.pageSizeOptions,
    };
  },
  // 监听是否刷新列表
  computed: {
    canfreshrank() {
      return this.$store.state.canfreshrank;
    },
  },
  watch: {
    canfreshrank: {
      handler(val) {
        if (val) {
          this.getSearch();
          this.initSortable();
          this.$store.commit("changeCanfreshRank", false);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getSearch();
    this.initSortable();
  },
  methods: {
    initSortable() {
      var that = this;
      const mytb = document.querySelector("#mytb tbody");
      new Sortable(mytb, {
        handle: ".ant-table-row",
        animation: 150,
        ghostClass: "blue-background-class",
        sort: true,
        onEnd({ newIndex, oldIndex }) {
          var dataSource = that.list;
          var source = dataSource[oldIndex]; // 谁
          //const destination = dataSource.value[newIndex]; // 移动到哪儿
          var pre_pos = "",
            after_pos = "";
          if (newIndex == 0) {
            pre_pos = 0;
            after_pos = dataSource[newIndex].pos;
          } else if (newIndex == that.list.length - 1) {
            after_pos = 0;
            pre_pos = dataSource[newIndex].pos;
          } else {
            if (newIndex < oldIndex) {
              pre_pos = dataSource[newIndex - 1].pos;
              after_pos = dataSource[newIndex].pos;
            } else {
              pre_pos = dataSource[newIndex].pos;
              after_pos = dataSource[newIndex + 1].pos;
            }
          }
          var data = {
            id: source.id,
            pre_pos: pre_pos,
            after_pos: after_pos,
          };
          that.axios.post("/api/admin/topGoods/setSort", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("排序成功");
              that.getSearch();
              that.initSortable();
            }
          });
        },
      });
    },
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.header.offsetHeight -
        // this.$refs.form.offsetHeight -
        //this.$refs.pagination.offsetHeight -
        45;
      this.$refs.box.style.display = "flex";
      if (this.table_h <= 250) {
        this.table_h = 400;
        this.$refs.box.style.display = "block";
      }
    },
    //获取列表
    getSearch() {
      var that = this;
      that.currentPage = 1;
      that.getList();
    },
    getList() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios.get("/api/admin/topGoods/list").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var list = res.data.resultObject;
          that.list = list;
        }
      });
    },
    handleCell(operation, num) {
      if (operation == "删除") {
        var that = this;
        this.$confirm({
          title: "提示",
          content: "是否确认删除此商品？",
          onOk() {
            var data = { id: num.id };
            that.axios.post("/api/admin/topGoods/del", data).then((res) => {
              if (that.$code(res)) {
                that.$message.success("删除成功");
                that.getSearch();
                that.initSortable();
              }
            });
          },
          onCancel() {},
        });
      } else {
        if (operation == "新增") {
          this.$store.commit("changeRankEditShow", true);
          this.$store.commit("changeRankId", "");
        }
        if (operation == "编辑") {
          this.$store.commit("changeRankEditShow", true);
          this.$store.commit("changeRankId", num.id);
        }
      }
    },
  },
};
</script>
<style scoped>
/deep/.pageHeader .ant-page-header,
/deep/.pageHeader .ant-page-header-heading {
  height: auto;
  border: 0 !important;
}
/deep/.ant-form-item {
  margin-right: 10px;
}

/deep/.ant-table-body::-webkit-scrollbar {
  background-color: #fff;
  width: 10px;
  height: 10px;
}
/deep/.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f2f5;
}
/deep/.ant-pagination .ant-pagination-item,
/deep/.ant-pagination .ant-pagination-jump-prev,
/deep/.ant-pagination .ant-pagination-jump-next {
  display: none;
}
.ant-btn {
  height: 36px;
  border-color: #f8f8f8;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #666;
  border-color: rgba(253, 105, 150);
}
form .ant-btn:hover,
form .ant-btn:focus {
  color: #fff;
}
.ant-table .ant-btn {
  height: 30px;
  padding: 0 20px;
}

.goodsInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 250px;
}
.goodsInfo img {
  width: 80px;
  height: 80px;
}
.goodsInfo > div {
  width: 165px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 80px;
}
.goodsInfo > div > div {
  width: 165px;
  text-align: left;
}
.goodsInfo > div > div span {
  color: rgba(253, 105, 150);
}
.goodsInfo p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}
</style>