<template>
  <a-drawer
    :visible="this.$store.state.rankEditShow"
    @close="onClose"
    :body-style="{ paddingBottom: '80px' }"
  >
    <a-page-header title="新增排行榜商品" sub-title=" " v-if="id == ''">
    </a-page-header>
    <a-page-header title="编辑排行榜商品" sub-title=" " v-else>
    </a-page-header>
    <a-form :form="form" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="24">
         <a-form-item label="选择商品">
            <a-select
            show-search
            placeholder="请输入并选择商品"
            v-decorator="['goods_id', { rules: [{ required: true, message: '请输入并选择商品！' }] }]"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :allowClear="true"
            :not-found-content="null"
            @search="handleSearch"
            dropdownClassName="dropdown"
              autocomplete="off"
          >
            <a-select-option v-for="d in goodsList" :key="d.id">
              {{ d.title }}
            </a-select-option>
          </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="是否展示 ">
            <a-radio-group
              v-decorator="[
                'is_show',
                {
                  rules: [{ required: true, message: '请选择是否展示!' }],
                },
              ]"
            >
              <a-radio :value="1"> 展示 </a-radio>
              <a-radio :value="0"> 隐藏 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="备注">
            <a-textarea
              :rows="5"
              v-decorator="[
                'remark',
                {
                  rules: [{ required: false, message: '请输入备注!' }],
                },
              ]"
              placeholder="请输入备注"
            >
            </a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div
      :style="{
        height: '80px',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        padding: '22px 40px 0 0',
        background: '#f8f8f8',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-button
        :style="{
          marginRight: '10px',
          padding: '0 50px',
          height: '36px',
          borderCcolor: '#dbdbdb',
        }"
        @click="onClose"
      >
        取消
      </a-button>
      <a-button
        type="primary"
        :style="{ padding: '0 50px', height: '36px' }"
        @click="onSure"
      >
        确定
      </a-button>
    </div>
  </a-drawer>
</template>
<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      id: "",
      goodsList:[]
    };
  },
  mounted() {},
  computed: {
    rankEditShow() {
      return this.$store.state.rankEditShow;
    },
  },
  watch: {
    rankEditShow: {
      handler(valnew) {
        if (valnew) {
          this.form.resetFields();
          this.id = this.$store.state.rankId;
          if (this.$store.state.rankId) {
            this.getOne();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.$store.commit("changeRankEditShow", false);
    },
    onSure() {
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          var data = values;
          if (!that.id) {
            that.axios.post("/api/admin/topGoods/add", data).then((res) => {
              that.confirmLoading = false;
              if (that.$code(res)) {
                that.$message.success("新增成功");
                that.$store.commit("changeRankEditShow", false);
                that.$store.commit("changeCanfreshRank", true);
              }
            });
          } else {
            data.id = that.id;
            that.axios
              .post("/api/admin/topGoods/modify", data)
              .then((res) => {
                that.confirmLoading = false;
                if (that.$code(res)) {
                  that.$message.success("修改成功");
                  that.$store.commit("changeRankEditShow", false);
                  that.$store.commit("changeCanfreshRank", true);
                }
              });
          }
        } else {
          that.confirmLoading = false;
        }
      });
    },
    
    handleSearch(value) {
      var that = this;
      if (value) {
        var data = {
          title: value,
        };
        that.axios
          .get("/api/admin/goods/list", {
            params: data,
          })
          .then((res) => {
            if (that.$code(res)) {
              that.goodsList = res.data.resultObject.data;
            }
          });
      } else {
        that.goodsList = [];
      }
    },
    getOne() {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      var data = { id: that.id };
      that.axios
        .get("/api/admin/topGoods/one", { params: data })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            this.goodsList=[{id:res.data.resultObject.dataoke_goods_id,title:res.data.resultObject.goods.title}]
            this.form.setFieldsValue({
              goods_id: res.data.resultObject.dataoke_goods_id,
              is_show: res.data.resultObject.is_show,
              remark: res.data.resultObject.remark,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
/deep/.ant-drawer-body {
  padding: 84px 40px;
  width: 100%;
  box-sizing: border-box;
}
.ant-page-header-ghost {
  position: absolute;
  min-height: 60px;
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  z-index: 1;
}
/deep/.ant-drawer-content-wrapper {
  width: 100% !important;
  min-width: 320px;
  max-width: 700px;
}
.ant-input,
/deep/.ant-select-selection--single,
/deep/.ant-select-selection__rendered,
/deep/.ant-cascader-input.ant-input {
  width: 100%;
}
.ant-form-item {
  height: auto;
}
/deep/.ant-select-search__field{
  padding:4px 11px
}
/deep/.ant-select-selection-selected-value,/deep/.ant-select-selection__placeholder{
  padding:0 11px

}
</style>
